
import { deleteBusiness, getBusiness } from '@/api/business';
import { UserModule } from '@/store/user';
import Kakao from '@/assets/js/kakao.min.js';
import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';

@Component({
  name: 'BusinessDetail',
  components: {
  },
})
export default class extends Vue {
  mounted() {
    this.getBusiness();
  }

  get isAdmin() {
    return UserModule.roles.indexOf('ROLE_ADMIN') > -1;
  }

  get isLogined() {
    return UserModule.token !== '';
  }

  get fileUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private business = {
    categoryName: '',
    title: '',
    content: '',
    recruitStart: '',
    recruitEnd: '',
    institutionName: '',
    applyCheck: '',
    dueDate: 0,
    progressStatus: false,
    supportFund: 0,
    files: [],
    views: 0,
    createDate: '',
    rssUrl: '',
    hasRole: false,
  }

  private applyVisible = false;

  private getBusiness() {
    getBusiness(this.$route.params.businessIdx).then((res) => {
      console.log(res);
      this.business = res.data;
    });
  }

  private filterContents(contents: string | null) {
    if (contents) return contents.replace(/(<([^>]+)>)/ig, '');
    return '';
  }

  private handleClickShare(sns: string) {
    /* eslint-disable */
    switch (sns) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`, '_blank');
        break;
      case 'twitter':
        window.open(`https://www.twitter.com/intent/tweet?url=${window.location.href}`, '_blank');
        break;
      case 'google':
        window.open(`https://www.plus.google.com/share?url=${window.location.href}`, '_blank');
        break;
      case 'kakao':
        const location = window.location.href;
        const contents = this.filterContents(this.business.content);
        Kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
            title: `${this.business.title} - 경남창업포털사인`,   // 콘텐츠의 타이틀
            description: contents,   // 콘텐츠 상세설명
            imageUrl: `${window.location.origin}/ft_logo.png`,
            link : {
              mobileWebUrl: location,   // 모바일 카카오톡에서 사용하는 웹 링크 URL
              webUrl: location, // PC버전 카카오톡에서 사용하는 웹 링크 URL
            },
          },
        });
        break;
      default:
        break;
    }
    /* eslint-enable */
  }

  private handleDeleteBusiness() {
    if (window.confirm('정말 사업공고를 삭제하시겠습니까?')) {
      deleteBusiness(this.$route.params.businessIdx).then(() => {
        window.alert('사업공고가 삭제되었습니다.');
        this.$router.push({ name: 'Business', params: { menuUid: this.$route.params.menuUid } });
      });
    }
  }
}
